import React from 'react';
const Settings = () => {
  return (
    <div className="page">
      <h2>Settings</h2>
      <p>Welcome to the Settings page!</p>
    </div>
  );
};
export default Settings;



