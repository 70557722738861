import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Personaldetails from "./pages/Personaldetails";
import Termsandcondition from "./pages/Termsandcondition";
import Aboutus from "./pages/Aboutus";
import Transactions from "./pages/Transactions";
import Login from "./components/Login";
import Register from "./components/Register";
import Salaryslip from "./pages/Salaryslip";
import Changepasssword from "./pages/Changepasssword";
import Forgetpassword from "./pages/Forgetpassword";
import Transactiondetails from "./pages/Transactiondetails";
import Salarydetails from './pages/Salerydetails'
import Sucess from './pages/Sucess'
import Salaryslipvies from './pages/Salaryslipvies'
import Pending from './pages/Pending'
import Failed from './pages/Failed'
import Toteldebit from './pages/Toteldebit'
import Notifications from './pages/Notifications'
import NotificationReply from './pages/NotificationReply'
import Faq from "./pages/Faq";
import Totel from "./pages/Totel";
import Setting from "./pages/Settings";
import TransactionView from "./pages/TransactionView";
import AddTransaction from "./pages/AddTransaction";
import Totekcreatee from "./pages/Totekcreatee";
import Resetpassword from "./pages/Resetpassword";
import Footer from "./components/Footer";
import { Container } from "react-bootstrap";
import "./App.css";

import axios from 'axios';

// Create Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Access the environment variable
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to handle 429 Too Many Requests
apiClient.interceptors.response.use(
  (response) => response, // Handle successful responses
  (error) => {
    const { config, response } = error;

    // Check if the error status is 429 (Too Many Requests)
    if (response && response.status === 429) {
      console.warn("Too many requests. Retrying after 5 seconds...");

      // Return a promise that retries the request after a delay (setTimeout)
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(apiClient(config)); // Retry the request after 5 seconds
        }, 5000); // 5 seconds delay
      });
    }

    // Reject the promise for other errors
    return Promise.reject(error);
  }
);

function App() {
  return (
    <React.Fragment>
      
      <Container fluid>
        <Router>
          <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Forgetpassword" element={<Forgetpassword />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Changepasssword" element={<Changepasssword />} />

          <Route path="/*" element={
            <>        
              <Sidebar />
          <div className="content">
            <Navbar />
            <main className="main" id="top">
              <Routes>
              <Route path="/home" element={<Home />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/Personaldetails" element={<Personaldetails />} />
                <Route path="/Salarydetails" element={<Salarydetails />} />
                <Route path="/Faq" element={<Faq />} />
                <Route
                  path="/Transactiondetails"
                  element={<Transactiondetails />}
                />
                <Route path="/Aboutus" element={<Aboutus />} />
                <Route path="Salaryslip" element={<Salaryslip />} />
                <Route
                  path="/Termsandcondition"
                  element={<Termsandcondition />}
                />
                <Route path="/settings" element={<Setting />} />
                <Route path="/Transactions" element={<Transactions />} />
                <Route path="/Sucess" element={<Sucess />} />
                <Route path="/Pending" element={<Pending />} />
                <Route path="/Failed" element={<Failed />} />
                <Route path="/Totel" element={<Totel />} />
                <Route path="/TransactionView" element={<TransactionView />} />
                <Route path="/AddTransaction" element={<AddTransaction />} />
                <Route path="/Salaryslipvies" element={<Salaryslipvies />} />
                <Route path="/Totekcreatee" element={<Totekcreatee />} />
                <Route path="/Toteldebit" element={<Toteldebit />} />
                <Route path="/Notifications" element={<Notifications />} />
                <Route path="/NotificationReply" element={<NotificationReply />} />
                <Route path="/Resetpassword" element={<Resetpassword />} />
            
           
              </Routes>
            </main>
          </div>
          <Footer />
          </>
        }/>
        </Routes>
              
        </Router>
      </Container>
    </React.Fragment>
  );
}
export default App;
