import React from 'react';
const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo">Logo</div>
      <div className="user-info">User Info</div>
    </div>
  );
};
export default Navbar;
