import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'; // Import SweetAlert
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';

export default function Resetpassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [con_password, setConPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);

  const userid = localStorage.getItem('userid');
  const token = localStorage.getItem('bear_token');
  const broker_id = localStorage.getItem('smalladmins_id');

  const navigate = useNavigate();

  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const onInputChangeconpassword = (e) => {
    setConPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConPasswordVisibility = () => {
    setShowConPassword((prevShowConPassword) => !prevShowConPassword);
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!password || !con_password) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Both password and confirm password are required!",
          color:"white",
          background: 'transparent',
        });
        setLoading(false);
        return;
      }

      if (password.length < 6) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Password must be at least 6 characters long!",
          color:"white",
          background: 'transparent',
        });
        setLoading(false);
        return;
      }

      if (password !== con_password) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Passwords do not match!',
          color:"white",
          background: 'transparent',
        });
        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}reset-employee-password`;

      const formData = new FormData();
      formData.append('password', password);
      formData.append('repeat_password', con_password);
      formData.append('smalladmins_id', broker_id);
      formData.append('user_id', userid);

      const config = {
        method: 'POST',
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then(function (response) {
          if (response.data.status === 0) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
              color:"white",
              background: 'transparent',
            });
            setTimeout(() => {
              navigate('/home');
            }, 1000);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.data.message,
              color:"white",
              background: 'transparent',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setLoading(false);
    }
  };

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 mt-5 position-relative">
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0">
                  <div className="col-md-12 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="text-center text-md-start">
                        <h4 className="mb-0">Reset your password?</h4>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md">
                          <form className="mb-1">
                            <label className="form-label" htmlFor="card-pass">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                value={password}
                                onChange={onInputChangepassword}
                                className="form-control"
                                type={showPassword ? 'text' : 'password'}
                                placeholder=""
                                id="password"
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <EyeSlash className="fs-3 eye" style={{ height: '21px' }} />
                                ) : (
                                  <EyeFill className="fs-3 eye" style={{ height: '21px' }} />
                                )}
                              </button>
                            </div>
                            <div className="mb-3" />
                            <label className="form-label" htmlFor="card-confirm">
                              Repeat Password
                            </label>
                            <div className="input-group">
                              <input
                                value={con_password}
                                onChange={onInputChangeconpassword}
                                className="form-control"
                                type={showConPassword ? 'text' : 'password'}
                                placeholder=""
                                id="password2"
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={toggleConPasswordVisibility}
                              >
                                {showConPassword ? (
                                  <EyeSlash className="fs-3 eye" style={{ height: '21px' }} />
                                ) : (
                                  <EyeFill className="fs-3 eye" style={{ height: '21px' }} />
                                )}
                              </button>
                            </div>
                            <div className="mb-3" />
                            <button
                              disabled={loading}
                              onClick={Submit}
                              className="btn btn-primary d-block w-100 mt-3"
                              type="submit"
                              name="submit"
                            >
                              Submit
                              {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
