import React, { useState, useEffect } from "react";
import axios from "axios";
import userprofile from "../assets/img/team/avatar.png";

export default function Aboutus() {
  const broker_id = localStorage.getItem("smalladmins_id");
  console.warn(broker_id)
  const [data, setData] = useState([]);
  console.warn(data)
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);




  console.warn(data.data);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}emp_cms-about-us`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data.data;
      const data1 = response.data.image_url;
      setLoading(false);

      setbaseurl(data1);
      setData({ data });
      console.warn("cus_cms-about-us", data1);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  // http://192.168.1.29:5000/api/cus_cms-about-us

  


if (loading) {
    return <div>
       <div id="preloader">
  <div id="loading-center">
    <div className="loader">
      <div className="waviy">
        <img
          src="https://swaninvestment.in/frontant/assets/img/loader.png"
          alt="Loader image"
        />
      </div>
    </div>
  </div>
</div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <h2 class="card-title">{data && data.data && data.data.title}</h2>
          <div className="row">
            {/* <div class="card"> */}

            <div className="col-md-4">
              <div className="aboutusimage">
              <img
                className=""
                src={`${baseurl}/${data && data.data && data.data.image}`}
                alt=""
            />
              </div>
            </div>
            <div className="col-md-8">
              <div class="card-body">
                <p class="card-text">
                  {data && data.data && data.data.descriptions}
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
     
    </>
  );
}
