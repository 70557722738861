import React, { useState, useEffect } from "react";
import userprofile from "../assets/img/team/2.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

import { FaEdit } from "react-icons/fa";
export default function Personaldetails() {
  const [profileinfo, setProfileinfo] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    // localStorage.removeItem(user_id);
    localStorage.clear();
    navigate('/');
  }

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn(response)
      setLoading(false);

      const data = response.data.data;
      setProfileinfo(data);
      console.warn("User Profile Data:", response.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };



 


if (loading) {
return <div>
   <div id="preloader">
<div id="loading-center">
<div className="loader">
  <div className="waviy">
    <img
      src="https://swaninvestment.in/frontant/assets/img/loader.png"
      alt="Loader image"
    />
  </div>
</div>
</div>
</div>
</div>;
}

if (error) {
return <div>Error: {error}</div>;
}
    return (
    <>
    <div className="card mb-3">
      <div
        className="bg-holder d-none d-lg-block bg-card"
        style={{
          backgroundImage:
            "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
        }}
      />
      {/*/.bg-holder*/}
      <div className="card-body position-relative">
        <div className="row">
          <div className="col-lg-8">
            <h3 className="mb-0">Personal Details</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="row g-0">
      <div className="col-lg-12">
        <div className="card mb-3">
          <div className="card-body bg-body-tertiary">
            <form className="row g-3">
              <div className="col-lg-6">
                <label className="form-label" htmlFor="first-name">
                  First Name
                </label>
                <input
                  className="form-control"
                  id="first-name"
                  type="text"
                  // defaultValue="05/05/2025"
                  value={profileinfo && profileinfo.broker_name}
                  disabled=""
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label" htmlFor="Marital-Status">
                  Marital Status
                </label>
                <input
                  className="form-control"
                  id="Marital-Status"
                  type="text"
                  value={profileinfo && profileinfo.marital_status}
                  disabled=""
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label" htmlFor="Aadhaarnumber">
                  Aadhaar Number
                </label>
                <input
                  className="form-control"
                  id="Aadhaarnumber"
                  type="number"
                  value={profileinfo && profileinfo.aadhar_no}
                  disabled=""
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label" htmlFor="PAN">
                  PAN Number
                </label>
                <input
                  className="form-control"
                  id="PAN"
                  type="text"
                  value={profileinfo && profileinfo.pan_number}
                  disabled=""
                />
              </div>
              {/* <div className="col-lg-6">
                <label className="form-label" htmlFor="PAN">
                  PAN Number
                </label>
                <input
                  className="form-control"
                  id="PAN"
                  type="text"
                  defaultValue="PAN32WS3"
                  disabled=""
                />
              </div> */}
              <div className="col-lg-6">
                <label className="form-label" htmlFor="PF">
                  PF Number (Optional)
                </label>
                <input
                  className="form-control"
                  id="PF"
                  type="text"
                  value={profileinfo && profileinfo.pf_no}
                  disabled=""
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label" htmlFor="ESI">
                  ESI Number (Optional)
                </label>
                <input
                  className="form-control"
                  id="ESI"
                  type="text"
                  value={profileinfo && profileinfo.esi_no}
                  disabled=""
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label" htmlFor="UAN">
                  UAN (Optional)
                </label>
                <input
                  className="form-control"
                  id="UAN"
                  type="text"
                  value={profileinfo && profileinfo.aadhar_no}
                  disabled=""
                />
              </div>
              {/* <div className="col-lg-6">
                <label className="form-label" htmlFor="ChangePassword">
                  Change Password
                </label>
                <input
                  className="form-control"
                  id="ChangePassword"
                  type="text"
                  defaultValue={74584214585}
                  disabled=""
                />
              </div> */}
              <div className="col-lg-6">
                <label className="form-label" htmlFor="Bankaccount">
                  Bank Account Number
                </label>
                <input
                  className="form-control"
                  id="Bankaccount"
                  type="text"
                 value={profileinfo && profileinfo.bank_ac_no}
                  disabled=""
                />
              </div>
              <div className="col-lg-12 d-flex justify-content-end">
                <button  onClick={logout} className="btn btn-primary" type="submit">
                  Log Out
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}
