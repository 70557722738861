




import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaSearch } from "react-icons/fa";
// import { IoMdPersonAdd } from "react-icons/md";
import { Button, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from "react-toastify";
import { FaFilePdf } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
import { MdOutlinePendingActions } from "react-icons/md";
import { MdOutlineSmsFailed } from "react-icons/md";
import { IoIosMail } from "react-icons/io";

import pdf from '../../src/assets/img/pdf.svg'
export default function Failed() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState('');
  const [ActiveEmployee, setActiveEmployee] = useState('');
  const [InactiveEmployee, setInactiveEmployee] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const broker_id = localStorage.getItem("smalladmins_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");


  const [total, settotal] = useState();
  console.warn(total)
  const [success, setsuccess] = useState();
  const [panding, setpanding] = useState();
  const [fialed, sefialed] = useState();
  const [Name, setName] = useState('');
  const [email, setemail] = useState('');




  useEffect(() => {
    getProfile();
  }, []);


  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-Failedtransactions-list`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Logging response and setting data
      console.warn("Response Data:", response.data.data);

      // Check if response.data.data is an array before setting state
      if (Array.isArray(response.data.data)) {
        setData(response.data.data);
        settotal(response.data.TotalTransaction);
        setsuccess(response.data.TotalSuccessTransaction);
        setpanding(response.data.TotalPendingTransaction);
        sefialed(response.data.TotalFailedTransaction);
        setName(response.data.data[0].emp_name);
        setemail(response.data.data[0].email);






        console.warn(response.data.data)
      } else {
        console.error(
          "Data received from API is not an array:",
          response.data.data
        );
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };







  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const downloadFile = async (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      // Handle null or undefined values for email or password
      toast.error("please choose a valid date!");
      setLoading(false);
      return;
    }






    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-FilterByDate-transactions-PDF?from_date=${fromDate}&to_date=${toDate}&smalladmins_id=${broker_id}&emp_id=${userid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf", // Specify the accept header for PDF response
          },
          responseType: "blob", // Set the responseType to 'blob' to receive binary data
        }
      );

      // Check if the response is a PDF file
      if (response.headers["content-type"] === "application/pdf") {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: "application/pdf" });
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement("a");
        // Set the href attribute of the link to the URL
        link.href = url;
        // Set the download attribute of the link to the desired filename
        link.download = "Employee_Transaction _Fitter_details.pdf";
        // Append the link to the document body
        document.body.appendChild(link);
        // Programmatically click the link to trigger the download
        link.click();
        // Remove the link from the document body
        document.body.removeChild(link);
      } else {
        toast.error("Data not found!");
      }
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  const handleDownload = () => {
    downloadFile(fromDate, toDate);
  };
  const handlesrarch = () => {
    srarch(fromDate, toDate);
  };



  const ALLdownloadFile = async () => {

    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-FilterByDate-transactions-PDF?&smalladmins_id=${broker_id}&emp_id=${userid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf", // Specify the accept header for PDF response
          },
          responseType: "blob", // Set the responseType to 'blob' to receive binary data
        }
      );

      // Check if the response is a PDF file
      if (response.headers["content-type"] === "application/pdf") {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: "application/pdf" });
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement("a");
        // Set the href attribute of the link to the URL
        link.href = url;
        // Set the download attribute of the link to the desired filename
        link.download = "Employee_Transaction _All_details.pdf";
        // Append the link to the document body
        document.body.appendChild(link);
        // Programmatically click the link to trigger the download
        link.click();
        // Remove the link from the document body
        document.body.removeChild(link);
      } else {
        toast.error("Data not found!");
      }
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };





  const srarch = async (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      // Handle null or undefined values for email or password
      toast.error("please choose a valid date!");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");

      if (!token) {
        toast.error("No token found. Please log in.");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-FilterByDate-transactions`,
        null,
        {
          params: {
            from_date: fromDate,
            to_date: toDate,
            smalladmins_id: broker_id,
            emp_id: userid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Typically JSON is used. Adjust if necessary.
          },
        }
      );

      console.warn("Response Data:", response.data.data);

      // Check if response.data.data is an array before setting state
      if (Array.isArray(response.data.data)) {
        setData(response.data.data);
        console.warn(response.data.data);
      } else {
        toast.error("Data not found!");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);

      if (error.response && error.response.status === 401) {
        toast.error("Unauthenticated. Please log in again.");
      } else {
        toast.error("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };












  const Delete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        setLoading(true);
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("cust_id", id);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}delete-small-admins-customers`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;
        if (data === 1) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("cust_id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}customer-small-admins-update-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      toast.error("There was an error changing the status.");
      setLoading(false);
    }
  };



  const handleClick = (id) => {
    localStorage.setItem("Addplan_id", id);
    navigate("/Add-More-plan");
  };
  const handleClickadd = (id) => {
    navigate("/Add-admins-customer");


  };
  const handleEditCustomerClick = (cust_id) => {
    localStorage.setItem('customer_id', cust_id);
    navigate("/Custumerdetailsedit");
  };


  const handleviveCustomerClick = (cust_id) => {
    localStorage.setItem('customer_id', cust_id);
    navigate("/Custumerdetails");
  };



  const filteredData = data.filter((item) =>
    item.transaction_id && item.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };




  const [BaseUrl, setBaseUrl] = useState();
  const [profile, setprofile] = useState();
  useEffect(() => {
    getProfileimge();
  }, []);


  const getProfileimge = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn(response)
      const data = response.data.data.profile;
      setprofile(data)
      const data1 = response.data.image_url;
      setBaseUrl(data1)
      console.warn(data1)



    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };





  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/* <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Employee  Transaction   Analytics</h2>
            </div>

          </div>
        </div> */}
           <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Employee Transaction Analytics</h2>
            </div>


                
            <div className="col-lg-4 d-flex justify-content-end">
            <Link to='/Transactions' className="linkWithoutUnderline">
            <Button variant="success">Back</Button>{' '}
            </Link>


            </div>

          </div>
        </div>



      </div>



      <div className="row d-flex justify-content-end g-3 mb-3 ">
        <div className="col-md-3 col-xxl-3">
          <Link to='/Transactions' className="linkWithoutUnderline">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">
                  Total
                </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {total}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
          <Link to='/Sucess' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> success</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <FaDiagramSuccessor className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                        {success}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
          <Link to='/Pending' className="linkWithoutUnderline">

            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> pending</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <MdOutlinePendingActions className="fs-4 text-warning" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 fw-bold  text-warning">
                        {panding}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-3 col-xxl-3">
          <Link to='/Failed' className="linkWithoutUnderline">

            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> failed</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <MdOutlineSmsFailed className="fs-4 text-danger" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                        {fialed}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>


      <div className="card mb-3">

        <div className="card-body position-relative d-flex justify-content-between">

          <div className="d-flex justify-content-end gap-3 align-items-center">
            <h2 className="mb-0">Employee  Failed   details</h2>
          </div>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">   ALL  Transaction details.pdf
            </Tooltip>}>
            <span className="d-inline-block">
              <button className="align-items-center btn-primary d-flex btn" onClick={ALLdownloadFile}><img src={pdf} alt="pdf" width={20} />
                Download PDF
              </button>
            </span>
          </OverlayTrigger>
        </div>

      </div>
      <div className="card mb-3">

        <div className="card-body position-relative d-flex justify-content-between">
          <div className="d-flex gap-3">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by Transaction id"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
            <div className="d-flex justify-content-end gap-3 align-items-center">
              <div className="d-flex align-items-center">
                <label className="me-1"></label>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="From Date"
                  className="form-control"
                />
              </div>
              <div className="d-flex align-items-center">
                <label className="me-1">   </label>
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="To Date"
                  className="form-control"
                /></div>
              <button className="btn btn-info" onClick={handlesrarch}>   Search</button>


            </div>
          </div>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Transaction Fitter details.pdf</Tooltip>}>
            <span className="d-inline-block">
              <button className="buttonfordownload  d-flex" onClick={handleDownload}>
                <img src={pdf} alt="pdf" width={40} />
              </button>
            </span>
          </OverlayTrigger>
        </div>

      </div>
      {/* <div className="card mb-3">

        <div className="card-body position-relative">

          <div className="row">

            <div className="row d-flex align-items-center">
              <div className="col-md-6 d-flex gap-1 align-items-center">
                <img className="rounded-circle"
                  src={
                    `${BaseUrl}/${profile}`
                  }
                  alt="" width={30} />
                <h5 className="mb-0">{Name} </h5>
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-center gap-1">
                <IoIosMail className="fs-5  text-primary" />
                <h5 className="mb-0 text-primary" >{email} </h5>
              </div>


            </div>

          </div>
        </div>
      </div> */}


      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        {/* <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by Transaction ID"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div> */}
        {loading && (
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-border">
              <thead className="">
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  <th className="text-800 sort align-middle">Transaction ID  </th>
                  {/* <th className="text-800 sort align-middle">  Name  </th> */}
                  <th className="text-800 sort align-middle">Transaction Amount</th>
                  <th className="text-800 sort align-middle">Date</th>
                  {/* <th className="text-800 sort align-middle"> Status</th> */}
                  <th className="text-800 sort align-middle"> Status</th>

                </tr>
              </thead>


              <tbody className="list" id="table-ticket-body">
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr>
                      <td>{startIndex + index + 1}</td>
                      <td>{item.transaction_id}</td>

                      {/* <td>{item.emp_name}</td> */}
                      <td>{item.transaction_amount}</td>
                      <td>{item.transaction_date}</td>
                      <td style={{
                        color: item.transaction_status === 'pending' ? 'blue' :
                          item.transaction_status === 'failed' ? 'red' :
                            item.transaction_status === 'success' ? 'green' : 'black'
                      }}>
                        {item.transaction_status}
                      </td>
                     


                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>


          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>








      </div>
      <ToastContainer />
    </>
  );
}
