import React, { useState, useEffect } from "react";
import userprofile from "../assets/img/team/2.jpg";
import axios from "axios";
import Swal from "sweetalert2";

import $ from "jquery";
import { FaEdit } from "react-icons/fa";

import { BsFillCreditCardFill } from "react-icons/bs";
import { Button, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


import withReactContent from "sweetalert2-react-content";
const Profile = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("bear_token");
  const [profileinfo, setProfileinfo] = useState();
  const [baseurl, setbaseurl] = useState();
  const [newImage, setNewImage] = useState();

  const [errors, setErrors] = useState({});
  const [fileimage, setFileimage] = useState();
  const credit = localStorage.getItem("credit");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    joining_date: "",
    email: "",
    personal_email: "",
    dept_name: "",
    roles_name: "",
    // joining_date: "",
    total_experience: "",
    confirmation_date: "",
    pre_work_exp: "",
    emergency_mobile: "",
    alternate_mobile: "",
    alternate_mobile: "",
    aadhar_no: "",
    permanant_address: "",
    current_address: "",
    profile: "",
    manager_name: "",



  });
  const [editedFields, setEditedFields] = useState({});
  const [error, setError] = useState({})



  useEffect(() => {
    // Initialize formData with profileinfo values
    setFormData(profileinfo || {});
  }, [profileinfo]);




  const handleChange = (e) => {
    const { name, value } = e.target;


    // if (name === "alternate_mobile" && !/^\d*$/.test(value)) {

    //   return;
    // }







    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileimage(URL.createObjectURL(file));
      setNewImage(file);
      setFormData({
        ...formData,
        profile: "",
      });
    }
  };


  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);

      const data = response.data.data;
      const name = response.data.data.name;
      localStorage.setItem('name', name);


      const data1 = response.data.image_url;
      // const name = response.data.profile;

      setbaseurl(data1)
      setProfileinfo(data);
      setLoading(false)
      localStorage.setItem("profileimage", data[0].profile);


      setFileimage(null);

    } catch (error) {
      console.warn("daat", error.response)
      console.error("Error fetching user profile:", error.response);
      console.warn(error.response)
      if (error.response) {
        console.error("Error response data:", error.response.data);
        // Handle Unauthorized case
        if (error.response && error.response.statusText === "Unauthorized") {
          localStorage.clear();
          navigate("/");
        }

      } else {
        // Generic error handling
        console.error("Error message:", error.message || "An unknown error occurred.");
      }

    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");
      let formDataToUpdate = new FormData();

      formDataToUpdate.append("smalladmins_id", broker_id);
      formDataToUpdate.append("name", formData.name);
      formDataToUpdate.append("email", formData.email);
      formDataToUpdate.append("personal_email", formData.personal_email);
      formDataToUpdate.append("dept_name", formData.dept_name);
      formDataToUpdate.append("manager_name", formData.manager_name);
      formDataToUpdate.append("roles_name", formData.roles_name);
      formDataToUpdate.append("joining_date", formData.joining_date);
      formDataToUpdate.append("total_experience", formData.total_experience);
      formDataToUpdate.append("confirmation_date", formData.confirmation_date);
      formDataToUpdate.append("pre_work_exp", formData.pre_work_exp);
      formDataToUpdate.append("alternate_mobile", formData.alternate_mobile);
      formDataToUpdate.append("emergency_mobile", formData.emergency_mobile);
      formDataToUpdate.append("aadhar_no", formData.aadhar_no);
      formDataToUpdate.append("permanant_address", formData.permanant_address);
      formDataToUpdate.append("current_address", formData.current_address);
      formDataToUpdate.append("status", '1');

      if (newImage) {
        formDataToUpdate.append("profile", newImage);
        const reader = new FileReader();
        reader.onload = function () {
          localStorage.setItem("profile_image", reader.result);
        };
        reader.readAsDataURL(newImage);
      } else {
        const storedImage = localStorage.getItem("profile_image");
        if (storedImage) {
          const blob = await (await fetch(storedImage)).blob();
          formDataToUpdate.append("profile", blob);
        }
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formDataToUpdate,
      };

      const response = await axios(config);
      const data = response.data;

      if (data.status === 1) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({

          icon: 'success',
          title: <strong>Profile Updated Successfully!</strong>,
          text: response.data.message,
          color: "white",
          background: 'transparent',
          // timer: 2000,
        })
          .then(() => {
            window.location.reload();
          });
        getProfile();
      } else if (data.status === 0) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: <strong>{data.message}</strong>,
          icon: "error",
          color: "white",
          background: 'transparent',
        })

      } else {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: <strong>{data.message}</strong>,
          icon: "error",
          color: "white",
          background: 'transparent',
        })
      }
      getProfile();

    } catch (error) {


      console.error("Error updating user profile:", error);
    }
  };


  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#imagePreview").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
        $("#imagePreview").hide();
        $("#imagePreview").fadeIn(650);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  $("#imageUpload").change(function () {
    readURL(this);
  });
  var $count = 0;

  var $eb = $('.edit-button');
  var $ei = $('.editable-input');
  var $ec = $('.editable-cell');

  //Editable input fields
  $eb.on('click', function () {
    $count++
    if ($count < 2) {
      $ei.prop('readonly', false).focus();
      $ei.prop('placeholder', '');
      $ei.val('');
      $(this).addClass('hide');
      $ec.addClass('editing');
    } else {
      $ei.prop('readonly', false).focus();
      $eb.addClass('hide');
      $ec.addClass('editing');
    }
  });


  $ei.on('blur', function () {
    $eb.removeClass('hide');
    $ei.prop('readonly', true);
    $ec.removeClass('editing');
  });


  if (loading) {
    return <div>
      <div id="preloader">
        <div id="loading-center">
          <div className="loader">
            <div className="waviy">
              <img
                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                alt="Loader image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  return (
    <div className="page">
      <div className="">
        {/* <form > */}
        <div className="d-flex justify-content-start">
          <div className="card width_30">
            <div className="card-header">

              <div className="avatar-upload">
                <div className="avatar-edit">

                  <label htmlFor="profile" className="custom-file-upload ">
                    <FaEdit className="edit-icon" />
                    <input
                      id="profile"
                      type="file"
                      name="profile"
                      onChange={handleImageChange}
                      style={{
                        position: "absolute",
                        opacity: 0,
                        top: 0,
                        left: 0,
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </div>
                <div className="avatar-preview">
                  <div id="imagePreview" >
                    <img
                      className="rounded-circle imagbeprofile"
                      id="profileimage"
                      src={
                        fileimage
                          ? fileimage
                          : formData.profile
                            ? `${baseurl}/${formData.profile}`
                            : userprofile
                      }
                      alt="profile"
                    />


                  </div>
                </div>
              </div>


              <h4 className="mb-1 text-center editable-cell">
                <div className="editable-field">
                  <input
                    className="editable-input w-100 text-center"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}

                  />
                  <FaEdit className="edit-button" />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}

                </div>


                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Verified"
                >
                  <small
                    className="fa fa-check-circle text-primary"
                    data-fa-transform="shrink-4 down-2"
                  />
                </span>
              </h4>
              {/* <h5 className="fs-9 fw-normal text-center">Worker</h5> */}
            </div>

            {/* <button
                className={activeTab === 2 ? "tablinks active" : "tablinks"}
                onClick={() => handleTabClick(2)}
              >
                Professional Details
              </button>
              <button
                className={activeTab === 3 ? "tablinks active" : "tablinks"}
                onClick={() => handleTabClick(3)}
              >
                Contact Details
              </button> */}
          </div>
          {/* {profileinfo && ( */}
          <div
            id="Profile"
            className="tabcontent"
            style={{ display: activeTab === 1 ? "block" : "none" }}
          >

            <div className="card mb-2">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 "> Profile</h3>
                  <Button className="gap-1 d-flex justify-content-end align-items-center" variant="info"><BsFillCreditCardFill />
                    {credit}</Button>{' '}


                  {/* <button
                    className="btn btn-falcon-primary btn-sm px-3"
                    type="button"
                  >
                    edit
                  </button> */}
                </div>
              </div>
            </div>

            <div className="card">

              <div className="d-flex justify-content-between align-items-center" style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                <h5 className="mb-0 "> Personal  Details</h5>
              </div>


              <div className="card-body">
                <div className="row g-3 mb-2">

                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Email">
                        Email
                      </label>
                      <input
                        className="form-control"
                        id="Email"
                        type="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="PersonalEmail">
                        Personal Email
                      </label>
                      <input
                        className="form-control"
                        id="PersonalEmail"
                        type="email"
                        name="personal_email"
                        value={formData.personal_email}
                        onChange={handleChange}
                      // 
                      />
                      {errors.personal_email && (
                        <div className="text-danger">{errors.personal_email}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 form_details">

                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="Alternativemobilenumber"
                      >
                        Alternative Mobile Number
                      </label>
                      <input
                        className="form-control"
                        id="Alternativemobilenumber"
                        type="text"
                        name="alternate_mobile"
                        value={formData.alternate_mobile && formData.alternate_mobile.slice(0, 10)}
                        onChange={handleChange}
                        pattern="[0-9]*"
                      />


                    </div>
                  </div>



                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Employee">
                        Employee ID
                      </label>
                      <input
                        className="form-control"
                        id="Employee"
                        type="text"
                        // defaultValue={profileinfo.emp_id}
                        Value={profileinfo && profileinfo.emp_id}
                        disabled
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Joining">
                        Joined Date
                      </label>
                      <input
                        className="form-control"
                        id="Joining"
                        // type="number"
                        name="joining_date"
                        value={formData.joining_date}
                        onChange={handleChange}

                      />
                    </div>
                  </div> */}

                  {/* <div className="col-lg-6 form_details"> */}
                  {/* <div className="form-group local-forms">
                    <label className="form-label" htmlFor="Department">
                      Department
                    </label>
                    <input
                      className="form-control"
                      id="Department"
                      type="text"

                      name='dept_name'
                      value={formData.dept_name}
                      onChange={handleChange}
                    />
                  </div> */}




                  {/* </div> */}





                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Role">
                        Role
                      </label>
                      <input
                        className="form-control"
                        id="Role"
                        type="text"
                        name='roles_name'
                        value={formData.roles_name}
                        onChange={handleChange}
                        disabled

                      />
                    </div>
                  </div>


                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Address">
                        Current Address
                      </label>
                      <textarea
                        className="form-control"
                        id="Address"
                        type="text"
                        name="current_address"
                        value={formData.current_address}
                        onChange={handleChange}
                      />

                    </div>
                  </div>

                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PermanentAddress"
                      >
                        Permanent Address
                      </label>
                      <textarea
                        className="form-control"
                        id="PermanentAddress"
                        type="text"
                        name="permanant_address"
                        value={formData.permanant_address}
                        onChange={handleChange}
                      />

                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Professional Details</h5>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Joining">
                        Joining Date
                      </label>
                      <input
                        className="form-control"
                        id="Joining"
                        // type="date"
                        name="joining_date"
                        value={formData.joining_date}
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Experience">
                        Total Experience
                      </label>
                      <input
                        className="form-control"
                        id="Experience"
                        type="text"
                        name="total_experience"
                        value={formData.total_experience
                        }
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Confirmation">
                        Confirmation Date Number
                      </label>
                      <input
                        className="form-control"
                        id="Confirmation"
                        // type="date"
                        name="confirmation_date"
                        value={formData.confirmation_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PreviousExperience"
                      >
                        Previous Work Experience (Optional)
                      </label>
                      <input
                        className="form-control"
                        id="PreviousExperienc"
                        type="text"
                        name="pre_work_exp"
                        value={formData.pre_work_exp}
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Department Details</h5>
                  </div>




                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PreviousExperience"
                      > Department name
                      </label>
                      <input
                        className="form-control"
                        id="PreviousExperience"
                        type="text"
                        name="pre_work_exp"
                        value={formData.dept_name}
                        onChange={handleChange}
                        disabled

                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    {" "}
                    <label className="form-label" htmlFor="Emergency">
                      Emergency Mobile Number
                    </label>
                    <input
                      className="form-control"
                      id="Emergency"
                      type="text"
                      name="emergency_mobile"
                      value={formData.emergency_mobile && formData.emergency_mobile.slice(0, 10)}
                      onChange={handleChange}
                    />
                  </div> */}


                  {/* <div className="col-lg-6">
                      <label className="form-label" htmlFor="Mobile">
                        Mobile No :
                      </label>
                      <input
                        className="form-control"
                        id="Mobile"
                        type="number"
                        name="mobile"
                        value={formData.mobile}
                        // Value={profileinfo.mobile}
                        onChange={handleChange}
                      />
                    </div> */}

                  <div className="col-lg-12 form_details">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-0 "> Manager Details</h5>
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PreviousExperience"
                      > Manager  Name
                      </label>
                      <input
                        className="form-control"
                        id="PreviousExperienc"
                        type="text"
                        name="pre_work_exp"
                        value={formData.manager_name}
                        onChange={handleChange}
                        disabled

                      />
                    </div>

                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button onClick={handleSubmit} className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* </form> */}
    </div>
  );
};
export default Profile;
