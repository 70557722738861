import React from 'react'
import companylogo from '../assets/img/logos/logo-invoice.png'
export default function Salerydetails() {

  return (
    <>
      <div className="card mb-3"> 
        <div className="card-body">
          <div className="row justify-content-between align-items-center">
            <div className="col-md">
              <h5 className="mb-2 mb-md-0">Salary Slip Details</h5>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                type="button"
              >
                <span className="fas fa-arrow-down me-1"> </span>Download (.pdf)
              </button>
              <button
                className="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                type="button"
              >
                <span className="fas fa-print me-1"> </span>Print
              </button>
              <button
                className="btn btn-falcon-success btn-sm mb-2 mb-sm-0"
                type="button"
              >
                <span className="fas fa-dollar-sign me-1" />
                Receive Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center text-center mb-3">
            <div className="col-sm-6 text-sm-start">
              <img
                src={companylogo}
                alt="invoice"
                width={150}
              />
            </div>
            <div className="col text-sm-end mt-3 mt-sm-0">
              <h2 className="mb-3">Salery</h2>
              <h5>Company Name</h5>
              <p className="fs-10 mb-0">
                156 University Ave, Toronto
                <br />
                On, Canada, M5H 2H7
              </p>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="container mb-5">

            <div className="row">

              <table className="table table-bordered">

                <tbody>
                  <tr>
                    <th scope="row">Salary Slip ID</th>
                    <td>1452145</td>

                  </tr>
                  <tr>
                    <th scope="row">Employee ID</th>
                    <td>550</td>

                  </tr>
                  <tr>
                    <th scope="row">Employee Name</th>
                    <td>Jhon</td>

                  </tr>
                  <tr>
                    <th scope="row">Salary Slip Date</th>
                    <td>26/01/2024 </td>

                  </tr>
                  <tr>
                    <th scope="row">- Bank Details</th>
                    <td>05455656546456 </td>

                  </tr>
                  <tr>
                    <th scope="row">- Salary Breakdown(Earnings) such as Basic Salary + HRA + Other Allowances</th>
                    <td>543654665465 </td>

                  </tr>
                  <tr>
                    <th scope="row">UAN Number
                    </th>
                    <td>4565466464565460</td>

                  </tr>
                  <tr>
                    <th scope="row">- Salary Deductions</th>
                    <td>00</td>

                  </tr>
                  <tr>
                    <th scope="row">- Net payable salary (Gross Earnings - Gross Deduction)</th>
                    <td>0.00</td>
                  </tr>
                  <tr>
                    <th scope="row">Holiday Wages</th>
                    <td>500.00</td>
                    <td colSpan={2} />
                  </tr>

                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-4">
                {" "}
                <br /> <span className="fw-bold">Net Pay : 24528.00</span>{" "}
              </div>
              <div className="border col-md-8">
                <div className="d-flex flex-column">
                  {" "}
                  <span>In Words</span>{" "}
                  <span>Twenty Five thousand nine hundred seventy only</span>{" "}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex flex-column mt-2">
                {" "}
                <span className="fw-bolder">For Company name Jewellers</span>{" "}
                <span className="mt-4">Authorised Signatory</span>{" "}
              </div>
            </div>

          </div>

        </div>
        <div className="card-footer bg-body-tertiary">
          <p className="fs-10 mb-0">
            <strong>Notes: </strong>We really appreciate your business and if
            there’s anything else we can do, please let us know!
          </p>
        </div>
      </div>
    </>

  );
};


