import React, { useEffect, useState } from 'react';
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoMdAdd, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import userprofile from "../assets/img/team/2.jpg";
import profile from "../assets/img/team/custer.png";


const Home = () => {

  // const [data, setdata] = useState("")
  const [YearlyClientAmount, setYearlyClientAmount] = useState("")
  const [NetGrowthPercentage, setNetGrowthPercentage] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const [TotalClientCount, setTotalClientCount] = useState("")
  const [ClientPercentage, setClientPercentage] = useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);



  useEffect(() => {
  
    const fetchDataWithDelay = async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      YearlyClient();
      
      await new Promise(resolve => setTimeout(resolve, 500)); 
      TotalClient();
      
      await new Promise(resolve => setTimeout(resolve, 500)); 
      getProfile();
      
      await new Promise(resolve => setTimeout(resolve, 500)); 
      getAutCheck();
    };
  

    fetchDataWithDelay();
  
  }, []); 
  
  


  const YearlyClient = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");
      const userid = localStorage.getItem("userid");


      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);


      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-selfemp-YearlyClient`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data;
      console.warn(data)
      setYearlyClientAmount(response.data.YearlyClientAmount)
      setNetGrowthPercentage(response.data.NetGrowthPercentage)


    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

 

  const TotalClient = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");
      const userid = localStorage.getItem("userid");


      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);


      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-selfemp-TotalClient`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data;
      setTotalClientCount(response.data.TotalClientCount)
      setClientPercentage(response.data.ClientPercentage)


    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };



  const [data, setData] = useState([]);
  

  const getProfile = async () => {
    const broker_id = localStorage.getItem('smalladmins_id');
    const userid = localStorage.getItem('userid');
    try {
      const formData = new FormData();
      formData.append('smalladmins_id', broker_id);
      formData.append('emp_id', userid);

      const token = localStorage.getItem('bear_token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-leads`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setData(response.data.data); // Assuming response.data contains the data array
      setLoading(false);

    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const filteredData = data.filter(
    (item) =>
      item.lead_name &&
      item.lead_name.toLowerCase().includes(searchTerm.toLowerCase())
  );



  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };







  const getAutCheck = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");
      const userid = localStorage.getItem("userid");


      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);


      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-emp-check-match-token`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.api_token;
      // alert(data)
      // console.warn(token)

      if (token !== data) {
        console.warn(token)
        // console.warn(data)
        window.location.href = '/';
        return;
      }
      
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };






  if (loading) {
    return <div>
      <div id="preloader">
        <div id="loading-center">
          <div className="loader">
            <div className="waviy">
              <img
                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                alt="Loader image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div className="page">
      <>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="card bg-100 shadow-none border">
              <div className="row gx-0 flex-between-center">
                <div className="col-sm-auto d-flex align-items-center">
                  <img
                    className="ms-n2"
                    src="./assets/img/illustrations/crm-bar-chart.png"
                    alt=""
                    width={90}
                  />
                  <div>
                    <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
                    <h4 className="text-primary fw-bold mb-0">
                      Swan <span className="text-info fw-medium">Investment</span>
                    </h4>
                  </div>
                  <img
                    className="ms-n4 d-md-none d-lg-block"
                    src="./assets/img/illustrations/crm-line-chart.png"
                    alt=""
                    width={150}
                  />
                </div>
                <div className="col-md-auto p-3">
                  <form className="row align-items-center g-3">
                    <div className="col-auto">
                      <h6 className="text-700 mb-0">Showing Data For: </h6>
                    </div>
                    <div className="col-md-auto position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <span className="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2">
                        {" "}
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-6 col-xxl-3">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h6 className="mb-0 mt-2 fs-8 d-flex align-items-center">
                  Yearly Client
                  <span
                    className="ms-1 text-400"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Calculated according to last week's sales"
                  >
                    <span
                      className="far fa-question-circle"
                      data-fa-transform="shrink-1"
                    />
                  </span>
                </h6>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row">
                  <div className="col">
                    <p className="font-sans-serif lh-1 mb-1 fs-6 d-flex"><FaRupeeSign />

                      {YearlyClientAmount >= 10000000
                        ? `${(YearlyClientAmount / 10000000).toFixed(1)}M`
                        : YearlyClientAmount >= 100000
                          ? `${(YearlyClientAmount / 100000).toFixed(1)}L`
                          : YearlyClientAmount >= 1000
                            ? `${(YearlyClientAmount / 1000).toFixed(1)}K`
                            : YearlyClientAmount}
                    </p>


                    {/* <p className="font-sans-serif lh-1 mb-1 fs-5">{YearlyClientAmount}    </p> */}
                    <span className="badge badge-subtle-success rounded-pill fs-11">
                      +{NetGrowthPercentage}%
                    </span>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xxl-3">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h6 className="mb-0 mt-2 fs-8">Total Client</h6>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col-auto align-self-end">
                    <div className="fs-6 fw-normal font-sans-serif text-700 lh-1 mb-1 d-flex">
                      {/* <profile /> */}
                      <img src={profile} height={30} width={30} alt="plan_image" className="img-fluid rounded-circle fs-6 me-1" />
                      {TotalClientCount >= 10000000
                        ? `${(TotalClientCount / 10000000).toFixed(1)}M`
                        : TotalClientCount >= 100000
                          ? `${(TotalClientCount / 100000).toFixed(1)}L`
                          : TotalClientCount >= 1000
                            ? `${(TotalClientCount / 1000).toFixed(1)}K`
                            : TotalClientCount}
                    </div>
                    <span className="badge rounded-pill fs-11 bg-200 text-primary">
                      <span className="fas fa-caret-up me-1" />
                      +{ClientPercentage}%
                    </span>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-12">
            <div
              className="card"
              id="TableCrmRecentLeads"
              data-list='{"valueNames":["name","email","status"],"page":8,"pagination":true}'
            >
              <div className="card-header d-flex flex-between-center flex-wrap gap-2">
                <h5 className="mb-0 fw-bold">Recent Leads</h5>

              </div>
              <div className="card-body py-0">
                <div className="table-responsive scrollbar">
                  <table className="table table-bordered  fs-10 mb-0">
                    <thead>
                      <tr className="bg-200 fs-9">
                        <th className="text-800 sort align-middle" data-sort="status">Sr. No.</th>

                        <th className="text-800 sort align-middle" data-sort="status">Name </th>

                        <th className="text-800 sort align-middle" data-sort="status">Email</th>
                        <th className="text-800 sort align-middle" data-sort="status">Phone </th>
                        <th className="text-800 sort align-middle" data-sort="status">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((item, index) => (
                        <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100" key={index}>

                          <td className="align-middle white-space-nowrap">{index + 1 + startIndex}</td>
                          <td className="align-middle white-space-nowrap">
                            <a className="text-800" href="void:javascript(0);">
                              <div className="d-flex align-items-center">
                                <div className="avatar avatar-xl">
                                  {userprofile == null ? (
                                    <>
                                      {/* Render some UI if userprofile is null */}
                                      {/* <IoPersonSharp className="fs-6 text-dark me-1" /> */}
                                    </>
                                  ) : (
                                    <>
                                      {/* Render the image if userprofile is not null */}
                                      <img
                                        src={`https://broker.swaninvestment.in/API/public/backend/uploads/investmentLeads/${item.image}`}
                                        height={35}
                                        width={35}
                                        alt="plan_image"
                                        className="img-fluid rounded-circle fs-6 me-1"
                                      />
                                    </>
                                  )}

                                </div>
                                <h6 className="mb-0 ps-2 name">{item.lead_name.charAt(0).toUpperCase() + item.lead_name.slice(1)}</h6>
                              </div>
                            </a>
                          </td>

                          <td className="align-middle white-space-nowrap text-primary email">{item.email}</td>



                          <td className="align-middle white-space-nowrap fw-bold">{item.phone}</td>

                          <td className="align-middle white-space-nowrap">
                            <small className="badge fw-semi-bold rounded-pill status badge-subtle-primary" >
                              {/* Assuming item.Lead_current_status contains the status string */}
                              <span style={{
                                color:
                                  item.Lead_current_status === "new lead" ? "blue" :
                                    item.Lead_current_status === "hot lead" ? "  red " :
                                      item.Lead_current_status === "won lead" ? "green" :
                                        "black"  // default color if none of the above conditions match

                              }}>
                                {item.Lead_current_status}
                              </span>


                            </small>
                          </td>

                          {/* <td >
                      <div className="d-flex gap-1 action_button">
                        <OverlayTrigger
                          key="View"
                          placement="top"
                          overlay={<Tooltip id={`View`}>View</Tooltip>}
                        >
                          <button
                            onClick={() => handleviveCustomerClick(item.id)}
                            className="bg-success"
                          >
                            <FaEye className="color_link" />
                          </button>
                        </OverlayTrigger>
                      </div></td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>

                  <div className="d-flex gap-2">
                    <div>
                      <button
                        className="border-0 "
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <IoIosArrowBack />

                      </button>
                    </div>

                    <div className="pagecurrent">
                      {currentPage}
                    </div>
                    <div>
                      <button
                        className="border-0 "
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >

                        <IoIosArrowForward />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>




      </>

    </div>
  );
};
export default Home;
