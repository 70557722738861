import React, { useState, useEffect } from 'react';
import proifle from "../assets/img/team/3-thumb.png";
import { IoIosNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import userprofile from "../assets/img/team/2.jpg";
import Swal from "sweetalert2";
import ramraja from "../assets/img/team/12.jpg";
import { Badge } from 'react-bootstrap';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [BaseUrl, setBaseUrl] = useState();
  const [profile, setProfile] = useState();
  const [AproveStatus, setAproveStatus] = useState();
  const [AproveCount, setAproveCount] = useState(null);
  const [name, setName] = useState();
  const browserId = localStorage.getItem("browserId");
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("smalladmins_id");
  console.warn(browserId)
  const userid = localStorage.getItem("userid");

  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    altername_phone: "",
    profile: null,
  });

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setButtonClicked(true);
  };


  const navigate = useNavigate();

  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    const token = localStorage.getItem("smalladmins_id");
    if (user_id == null) {
      window.location.href = '/';
    }
    if (token == null) {
      window.location.href = '/';
    }
  }, []);




  const logout = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: " You won't be able to revert this !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2365af',
      cancelButtonColor: '#d33',
      background: '#fff',
      color: "#fff",
      confirmButtonText: 'Yes, log out!'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
      }
    });
  };


  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 3) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getProfile();
    shareStatus();
    shareStatuslist();

  }, []);

  useEffect(() => {
    setTimeout(() => {
      getAutCheck();
    }, 3000);
  }, []);

  const getProfile = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data.profile;
      const name = response.data.data.name;
      setProfile(data);
      setName(name);
      const data1 = response.data.image_url;
      localStorage.setItem("credit", response.data.data.user_credit);
      setBaseUrl(data1);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };



  const getAutCheck = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");
      const userid = localStorage.getItem("userid");


      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);


      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-emp-check-match-token`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.api_token;
      // alert(data)
      // console.warn(token)

      if (token !== data) {
        console.warn(token)
        // console.warn(data)
        window.location.href = '/';
        return;
      }

    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };


  const capitalizeWords = (str) => {
    if (!str) return ""; // return an empty string if str is undefined or null
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Inside your component's render method or JSX


  const shareStatus = async () => {
    try {
      const userid = localStorage.getItem("userid");
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("user_id", userid);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toemployee-notification-count`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn(response.data.data)

      setAproveCount(response.data.data);
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  const shareStatuslist = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("user_id", userid);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toemployee-notification-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn("response.data.data", response.data.data)
      setAproveStatus(response.data.data);

    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  const formatDate = (dateString) => {
    // Split the input date string
    const [day, month, year] = dateString.split('-').map(Number);

    // Create a new Date object
    const date = new Date(year, month - 1, day); // Months are 0-based in JavaScript Date

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'

    // Format minutes with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Format the date as MM/DD/YYYY h:mm AM/PM
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${formattedMinutes} ${ampm}`;
  };
  const removeHtmlTags = (text) => text.replace(/<[^>]*>/g, '');




  const seennotification = async () => {
    try {
      const userid = localStorage.getItem("userid");
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("user_id", userid);
      formData.append("is_seen", 1); // Marking the notification as seen

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toemployee-notification-seen`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn(response.data.data); // You can handle response here
      window.location.reload();

    } catch (error) {
      console.error("Error sending request:", error);
    }
  };






  return (
    <div className={`p-0 navbar-top ${isSticky ? 'navbar-glass-shadow' : ''}`}>
      <nav className="navbar navbar-light navbar-top navbar-expand-lg" data-double-top-nav="data-double-top-nav">
        <div className="w-100">
          <div className="d-flex flex-between-center">
            <button
              className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarDoubleTop"
              aria-controls="navbarDoubleTop"
              aria-expanded="false"
              aria-label="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>

            <div style={{ textAlign: 'center' }} className='w-100'>
              {name ? (
                <h4 style={{ color: "#3498db" }}>
                  <span className='text-primary'> Welcome to :</span> Employee Dashboard ({name && capitalizeWords(name)
                  })



                </h4>
              ) : (
                <h5 style={{ color: "green" }}>
                  Welcome to Employee Dashboard.
                </h5>
              )}
            </div>

            <ul className="navbar-nav navbar-nav-icons ms-auto flex-row gap-3 align-items-center">
              <li className="position-relative">
                <div onClick={toggleDropdown  && seennotification} className={buttonClicked ? "clicked" : ""}>
                <Link to="/notifications" style={{ textDecoration: "none" }}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <IoIosNotifications className="icon_size text-primary" color="#ff0000" />
                    {AproveCount ? (
                      <Badge
                        pill
                        bg="danger"
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          left: '12px'
                        }}
                      >
                        {AproveCount}
                      </Badge>
                    ) : (
                      <Badge
                        pill
                        bg="danger"
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          left: '12px'
                        }}
                      >
                        0
                      </Badge>
                    )}
                  </div>
                  </Link>
                </div>


                {isDropdownOpen && AproveCount && (
                  <ul
                    className={`dropdown-menu dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg ${buttonClicked ? "d-block" : ""
                      }`}
                  >
                    <li className="nav-item dropdown">
                      <div className="card card-notification shadow-none">
                        <div className="card-header">
                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <h6 className="card-header-title mb-0">
                                Notifications
                              </h6>
                            </div>
                            <div className="col-auto ps-0 ps-sm-3">
                              <a className="card-link fw-normal" href="#">
                                {/* Mark all as read */}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="list-group-item">
                            <a
                              className="notification notification-flush notification-unread"
                              href="#!"
                            >
                              <div className="notification-avatar mx-1">
                                {/* <div className="avatar avatar-2xl me-1">
                                  <img
                                    className="rounded-circle"
                                    src="../../assets/img/team/1-thumb.png"
                                    alt=""
                                  />
                                </div> */}
                              </div>
                              {AproveCount > 0 ? (
                                AproveStatus && AproveStatus.length > 0 ? (
                                  AproveStatus.map((message, index) => (
                                    <div key={index} className="notification-body">
                                      <p className="mb-1 capitalizes" >
                                        {index + 1}. {removeHtmlTags(message.message)}
                                      </p>
                                      <span className="notification-time">
                                        <span
                                          className="me-2"
                                          role="img"
                                          aria-label="Emoji"
                                        >
                                          💬
                                        </span>
                                        {formatDate(message.date)}
                                        {/* Just now */}
                                      </span>
                                    </div>
                                  ))
                                ) : (
                                  <p className="mb-1">No new messages</p>
                                )
                              ) : (
                                <p className="mb-1">No new messages</p>
                              )}

                            </a>
                          </div>
                        </div>
                        <div
                          className="scrollbar-overlay"
                          style={{ maxHeight: "19rem" }}
                        >
                          <div className="list-group list-group-flush fw-normal fs-10">
                            {/* Notification items */}
                          </div>
                        </div>
                        <div className="card-footer text-center border-top">
                          <Link className="card-link d-block" to='/Notifications'>
                            View all
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className="avatar avatar-xl">
                    {profile ? (
                      <img className="rounded-circle" src={`${BaseUrl}/${profile}`} alt="" />
                    ) : (
                      <img className="rounded-circle" src={userprofile} alt="" />
                    )}
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-caret dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                  <div className="bg-white dark__bg-1000 rounded-2 py-2">
                    <Link className="dropdown-item" to="/Profile">Profile ({name && capitalizeWords(name)
                    })</Link>
                    <a className="dropdown-item" href="/Resetpassword">Change Password</a>
                    <a className="dropdown-item" onClick={logout}>Logout</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
