import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
import { GoogleLogin } from 'react-google-login';
import { Spinner, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';

export default function Changepassword() {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const [logoData, setLogoData] = useState(null);
  console.warn(logoData)


  const [error, setError] = useState(null);

  const [password, setPassword] = useState('');
  const [con_password, setConPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();
  const email = localStorage.getItem("email");

  const onInputChangeOtp = (e) => {
    setOtp(e.target.value.slice(0, 6));
  };
  const onInputChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onInputChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConPasswordVisibility = () => {
    setShowConPassword((prevShowConPassword) => !prevShowConPassword);
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!otp) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Otp is missing!",
          // timer: 2000
        });
        // toast.error("Otp is missing!");
        setLoading(false);
        return;
      }
      if (password.length < 6) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Password must be at least 6 characters long!",
          // timer: 2000
        });
        // toast.error("Password must be at least 6 characters long!");
        setLoading(false);
        return;
      }
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Passwords do not match!",
          // timer: 2000
        });
        // toast.error("Passwords do not match!");
        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}employee-reset-password`;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("otp", otp);
      formData.append("password", password);

      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config).then(function (response) {
        if (response.data.status == 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            color: "white",
            background: 'transparent',
            // timer: 2000
          });
          // toast.success(response.data.message);
          setTimeout(() => {
            navigate('/');
          }, 1000);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Invalid otp !",
            color: "white",
            background: 'transparent',
            // timer: 2000
          });
          // toast.error(response.data.message);
        }
      }).finally(() => {
        setLoading(false);
      });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };




  useEffect(() => {
    // Fetch data from API
    axios
      .post("https://broker.swaninvestment.in/API/public/api/employee-logo-withoutlogin")
      .then((response) => {
        if (response.data.status === 1) {
          setLogoData(response.data); // Set the API response data if status is 1 (Success)
        } else {
          setError("Failed to fetch data.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching data");
      });
  }, []);

  return (
    <main className="main" id="top">
      <div className="">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-12 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="assets/img/icons/spot-illustrations/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center bgcolornew d-flex align-items-center">
                    <div>  <div className="position-relative p-4 pt-md-5 pb-md-7" data-bs-theme="light">
                      <div className="bg-holder bg-auth-card-shape" style={{ backgroundImage: "url(assets/img/icons/spot-illustrations/half-circle.png)" }} />
                      <div className="z-1 position-relative">
                        <a className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder" href="index.php">     {logoData && logoData.logo_url && logoData.data ? (
                          <div>
                            <img
                              src={`${logoData.logo_url}/${logoData.data}`} // Full logo URL
                              alt="Employee Logo"
                              style={{ width: "200px", height: "auto" }}
                            />
                            <p className='mt-5  text-start new_css'> {logoData.short_desc}</p> {/* Display the short description */}
                          </div>
                        ) : (
                          <p>No logo data available</p>
                        )}</a>

                      </div>
                    </div>
                      <div className="mt-3 mb-4 mt-md-4 mb-md-5" data-bs-theme="light">
                        <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-dark opacity-75">
                          Read our <a className="text-decoration-underline text-dark" href="#!">terms</a> and <a className="text-decoration-underline text-dark" href="#!">conditions</a>
                        </p>
                      </div></div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="text-center text-md-start">
                        <Link to='/' className="forgetpassword" style={{ position: 'absolute', top: '25px', right: '44px' }}>
                          <Button variant="primary">Back</Button>{' '}
                        </Link>
                        <h4 className="mb-0"> Reset your password?</h4>
                        <p className="mb-4">Enter your OTP we'll send you.</p>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-8 col-md">
                          <form className="mb-3">
                            <label className="form-label" htmlFor="card-otp">OTP*</label>
                            <input
                              value={otp}
                              onChange={onInputChangeOtp}
                              className="form-control"
                              type="number"
                              placeholder="Enter OTP"
                              max={999999}
                            />
                            <div className="mb-3" />
                            <label className="form-label" htmlFor="card-pass">Enter New Password</label>
                            <div className="input-group">
                              <input
                                value={password}
                                onChange={onInputChangePassword}
                                className="form-control"
                                id="card-password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter New Password"
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <EyeSlash className="fs-3 eye" style={{ height: "21px" }} />
                                ) : (
                                  <EyeFill className="fs-3 eye" style={{ height: "21px" }} />
                                )}
                              </button>
                            </div>
                            <div className="mb-3" />
                            <label className="form-label" htmlFor="card-confirm">Confirm Password</label>
                            <div className="input-group">
                              <input
                                value={confirmPassword}
                                onChange={onInputChangeConfirmPassword}
                                className="form-control"
                                id="card-confirm-password"
                                type={showConPassword ? "text" : "password"}
                                placeholder="Re Enter Your Password"
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={toggleConPasswordVisibility}
                              >
                                {showConPassword ? (
                                  <EyeSlash className="fs-3 eye" style={{ height: "21px" }} />
                                ) : (
                                  <EyeFill className="fs-3 eye" style={{ height: "21px" }} />
                                )}
                              </button>
                            </div>
                            <div className="mb-3" />
                            <button
                              disabled={loading}
                              onClick={Submit}
                              className="btn btn-primary d-block w-100 mt-3"
                              type="submit"
                              name="submit"
                            >
                              Submit
                              {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  )
}
