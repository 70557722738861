// sidebar.js

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { MdDashboard } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { IoDocumentText } from "react-icons/io5";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { TbPageBreak } from "react-icons/tb";
import { MdOutlineRoundaboutRight } from "react-icons/md";
import { TbLetterMSmall } from "react-icons/tb";
import { FaQrcode } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { MdNotificationsActive } from "react-icons/md";
import Logo from "../assets/img/logos/swan.logo.png";
const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("");
  const location = useLocation();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // Add or remove class from the <html> tag based on the 'collapsed' state
    if (isSidebarOpen) {
      document.documentElement.classList.remove("navbar-vertical-collapsed");
    } else {
      document.documentElement.classList.add("navbar-vertical-collapsed");
    }
  }, [isSidebarOpen]);
  useEffect(() => {
    // Add or remove class from the <html> tag based on the 'collapsed' state
    if (isSidebarOpen) {
      document.documentElement.classList.remove("navbar-vertical-collapsed");
    } else {
      document.documentElement.classList.add("navbar-vertical-collapsed");
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    // Set activeMenu based on the current path
    const path = location.pathname.substring(1); // Remove leading '/'
    if (path) {
      setActiveMenu(path);
    }
    // else {
    //   setActiveMenu("dashboard"); // Default to 'dashboard' if path is empty
    // }
  }, [location.pathname]);

  const handleMenuClick = (menuId) => {
    setActiveMenu(menuId);
  };
  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
      {/* // <div> */}
      <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
        <div className="d-flex align-items-center">
          <div className="toggle-icon-wrapper">
            <button
              className="btn navbar-toggler-humburger-icon toggle-btn navbar-vertical-toggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              onClick={toggleSidebar}

              title="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
          </div>
          <Link className="navbar-brand" to="/Home">
            <div className="d-flex align-items-center">
              <img className="me-2" src={Logo} alt="" width={130} />
              {/* <span class="font-sans-serif text-primary">falcon</span> */}
            </div>
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content scrollbar">
            <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
              <li className={`nav-item new_menu ${activeMenu === "dashboard" ? "active" : ""}`}>
                {/* parent pages*/}
                <Link className="nav-link" to="/Home" onClick={() => handleMenuClick("dashboard")}>
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdDashboard />
                    </span>
                    <span className="nav-link-text ps-1">Dashboard</span>
                  </div>
                </Link>
              </li>


              <li className={`nav-item new_menu ${activeMenu === "Transactions" ? "active" : ""}`}>
                <Link className="nav-link" to="/Transactions" onClick={() => handleMenuClick("Transactions")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <GrTransaction />
                      </span>
                      <span className="nav-link-text ps-1">Transaction</span>
                    </div>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>

              <li className={`nav-item new_menu ${activeMenu === "Salaryslip" ? "active" : ""}`}>
                <Link className="nav-link" to="/Salaryslip" onClick={() => handleMenuClick("Salaryslip")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <GiReceiveMoney />
                      </span>
                      <span className="nav-link-text ps-1">Salary</span>
                    </div>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>


              <li className={`nav-item new_menu ${activeMenu === "Notifications" ? "active" : ""}`}>
                <Link className="nav-link" to="/Notifications" onClick={() => handleMenuClick("Notifications")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <MdNotificationsActive />
                      </span>
                      <span className="nav-link-text ps-1"> Notifications



                      </span>
                    </div>
                  </div>

                </Link>
              </li>


              <li className={`nav-item new_menu ${activeMenu === "Termsandcondition" ? "active" : ""}`}>
                <Link className="nav-link" to="/Termsandcondition" onClick={() => handleMenuClick("Termsandcondition")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <TbLetterMSmall />
                      </span>
                      <span className="nav-link-text ps-1">T&amp;C


                      </span>
                    </div>
                  </div>


                </Link>
                {/* more inner pages*/}
              </li>
              <li className={`nav-item new_menu ${activeMenu === "Profile" ? "active" : ""}`}>
                <Link className="nav-link" to="/Profile" onClick={() => handleMenuClick("Profile")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <CgProfile />
                      </span>
                      <span className="nav-link-text ps-1"> Profile



                      </span>
                    </div>
                  </div>

                </Link>
              </li>


              <li className={`nav-item new_menu ${activeMenu === "Aboutus" ? "active" : ""}`}>
                <Link className="nav-link" to="/Aboutus" onClick={() => handleMenuClick("Aboutus")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <MdOutlineRoundaboutRight />                      </span>
                      <span className="nav-link-text ps-1">About Us

                      </span>
                    </div>
                  </div>

                </Link>
                {/* more inner pages*/}
              </li>

              <li className={`nav-item new_menu ${activeMenu === "Faq" ? "active" : ""}`}>
                <Link className="nav-link" to="/Faq" onClick={() => handleMenuClick("Faq")}>
                  <div className="d-flex align-items-center">

                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <FaQrcode />
                      </span>
                      <span className="nav-link-text ps-1">   FAQs



                      </span>
                    </div>
                  </div>

                </Link>
              </li>


            </ul>
          </div>
        </div>
      </nav>


    </div>
  );
};

export default Sidebar;
