



import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaFilePdf, FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import userprofile from "../assets/img/team/2.jpg";


import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import {
  MdDelete,
  MdOutlinePendingActions,
  MdOutlineSmsFailed,
  MdArrowOutward
} from "react-icons/md";

import { IoMdAdd, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Button, Row } from "react-bootstrap";
import Swal from "sweetalert2";

import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
// import pdf from './../../assets/img/pdf.svg'

export default function Toteldebit() {
  const [data, setData] = useState([]);


  const [loading, setLoading] = useState(false);
  const [baseurl, setbaseurl] = useState();
  const [baseurlprofil, setbaseurlprofil] = useState();
  // alert(baseurlprofil)
  const [profilimage, setprofilimage] = useState();
  const [CustomerName, setCustomerName] = useState();
  console.warn(CustomerName)

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState("");
  const [ActiveEmployee, setActiveEmployee] = useState("");
  const [PendingEmployee, setPendingEmployee] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const emp_id = localStorage.getItem("employee_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("smalladmins_id");
  const customer_id = localStorage.getItem("customer_id");
  // const broker_id = localStorage.getItem("smalladmins_id");
  // const userid = localStorage.getItem("userid");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [DebitTransaction, setDebitTransaction] = useState("");
  const [crediTransaction, setcrediTransaction] = useState("");
  const [totel, settotel] = useState("");
  // alert(totel)

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);

      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-Debit-trnx`,
        // `${process.env.REACT_APP_API_URL}small-admins-narmalCustomer-transactions-lists`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setData(response.data.data);
      const TotalcustomercrediTransaction = response.data.TotalcustomercrediTransaction;
      const totalEmp = response.data.TotalcustomerTransaction;
      const TotalcustomerDebitTransaction = response.data.TotalcustomerDebitTransaction;
      settotel(totalEmp);
      setDebitTransaction(TotalcustomerDebitTransaction);
      setcrediTransaction(TotalcustomercrediTransaction);
      // alert(totalEmp)
      // const Inactiveuser_credit =response.data.userCreditBal.user_credit;
      // setuser_credit(Inactiveuser_credit);
      setbaseurl(response.data.trnximageUrl)
      setbaseurlprofil(response.data.profileImage)

      // const empNames = response.data.map(transaction => transaction.emp_name);
      // setCustomerName(empNames)
      // console.warn(empNames)

      // Do something with empNames if needed, e.g., set to state or console log
      // console.warn(empNames);
      // You can set the first name to state as an example
      // if (empNames.length > 0) {
      //   setCustomerName(empNames[0].emp_name);
      // }
      // alert("jfj")






    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const getFilteredTransactions = async (fromDate, toDate) => {
    if (data.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Sorry, no data found!",
        color: "white",
        background: 'transparent',
      });
      return;
    }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        color: "white",
        background: 'transparent',
      });
      setLoading(false);
      return;
    }
    // formData.append("emp_id", userid);

    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-FilterByDate-transactions?from_date=${fromDate}&to_date=${toDate}&smalladmins_id=${broker_id}&emp_id=${userid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || "Sorry, no data found!",
          color: "white",
          background: 'transparent',
        });
        setData([]); // Clear data if no results found
      } else {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching filtered transactions:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "An unexpected error occurred!",
        color: "white",
        background: 'transparent',
      });
    } finally {
      setLoading(false); // Ensure loading is set to false whether successful or error
    }

    //   setData(response.data.data);
    // } catch (error) {
    //   console.error("Error fetching filtered transactions:", error);
    // }
  };


  const downloadFile = async (fromDate, toDate) => {
    if (data.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Sorry, no data found!",
        color: "white",
        background: 'transparent',
      });
      return;
    }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        color: "white",
        background: 'transparent',
      });
      setLoading(false);
      return;
    }
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-FilterByDate-transactions-PDF?from_date=${fromDate}&to_date=${toDate}&smalladmins_id=${broker_id}&emp_id=${userid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf",
          },
          responseType: "blob",
        }
      );

      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Normal_Customer_Filter_Transactions.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Response is not a PDF file");
      }
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  const handleDownload = () => {
    downloadFile(fromDate, toDate);
  };

  const handleFilter = () => {
    getFilteredTransactions(fromDate, toDate);
  };

  const handleClick = (id) => {
    localStorage.setItem("Addplan_id", id);
    navigate("/Add-More-plan");
  };

  const handleClickAdd = (id) => {
    navigate("/Add-admins-customer");
  };

  const handleEditCustomerClick = (cust_id) => {
    localStorage.setItem("customer_id", cust_id);
    navigate("/Custumerdetailsedit");
  };

  // const handleViewCustomerClick = (customer_id) => {
  //   localStorage.setItem("customer_idTransaction", customer_id);
  //   navigate("/AddTransaction");
  // };

  const filteredData = data.filter(
    (item) =>
      item.transaction_id &&
      item.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleviveCustomerClick = (id) => {
    // console.warn(id)
    // alert(id)
    localStorage.setItem('id', id);
    navigate("/TransactionView");
  };

  // const [name,setName] =useState



  const storedName = localStorage.getItem('name');
  // alert(storedName)
  // setName(storedName)

  useEffect(() => {
    getProfile();

  }, []);

  useEffect(() => {
    getProfileee();


  }, []);

  const [profile, setprofile] = useState("")
  const [usrl, setusrl] = useState("")
  const [name, setname] = useState("")
  const [usercredit, setusercredi] = useState("")
  // alert(usercredit)


  const getProfileee = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      // console.warn(response)
      const data = response.data.data.profile;
      setprofilimage(response.data.image)

      setprofile(data)
      const data1 = response.data.image_url;
      console.warn(data1)
      setusrl(data1);
      const name = response.data.data.name;
      const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
      setname(capitalizedName);

      const user_credit = response.data.data.user_credit;
      setusercredi(user_credit)




    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const capitalizeWords = (str) => {
    if (!str) return ""; // return an empty string if str is undefined or null
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };


  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Employee Transactions Analytics    </h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/home" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">

        <div className="col-md-4 col-xxl-3">
          <Link to="/Transactions" className="linkWithoutUnderline ">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total Transactions</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">

                    <GrTransaction className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {totel}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link to="/Totekcreatee" className="linkWithoutUnderline ">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total Credit</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {crediTransaction}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link to="/Toteldebit" className="linkWithoutUnderline active">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Total Debit</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-info" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-info">
                        {DebitTransaction}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>


      </div>

      <div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
              <h5 className="mb-0 capitalize d-flex align-items-center">

                {profile == null ?

                  <>
                    <img
                      src={userprofile} // Use the imported image here
                      height={35}
                      width={35}
                      alt="user_profile"
                      className="img-fluid rounded-circle fs-6 me-1"
                    />
                  </>
                  :



                  <img
                    src={`${usrl}/${profile}`} height={35}
                    width={35} alt="plan_image" className="img-fluid rounded-circle fs-6  me-1" />
                }   {name}</h5>
            </div>
            <div className="col-lg-8 d-flex align-items-center justify-content-end   ">
              <h5 className="mb-0 capitalize d-flex align-items-center text-success">

                <MdOutlineAccountBalanceWallet className="fs-4 " /> {usercredit}</h5>
            </div>
            <div className="col-lg-4 d-flex justify-content-end text-end">
              {/* <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled"><small>Add Normal Customer Transaction</small></Tooltip>
                }
              >
                 <button className="btn  text-light bg-success" onClick={ ()=> {handleViewCustomerClick(customer_id)}}>
                  <IoMdAdd className="me-1 fs--2" /> Add Transaction  
                </button>
              </OverlayTrigger> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Transaction Id"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-9 text-end">
              <Row>
                <div className="col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    placeholder="From Date"
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    placeholder="To Date"
                  />
                </div>
                <div className="col-md-4 d-flex gap-1 justify-content-evenly  align-item-space-between">
                  {/* <div className="mb-3"> */}
                  <button className="btn btn-primary " onClick={handleFilter}>
                    Search
                  </button>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {" "}
                        Download Transaction PDF
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <button
                        className="buttonfordownload"
                        onClick={handleDownload}
                      >
                        {/* <img src={pdf} alt="pdf" width={60}  type="button" /> */}
                        <FaCloudDownloadAlt className="fs-4 text-dark" />
                      </button>
                    </span>
                  </OverlayTrigger>
                  {/* <MdArrowOutward className="" /> */}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>

                  <th>Transaction Id</th>
                  <th>Payment mode </th>
                  <th>Payment type </th>
                  {/* <th>Image</th> */}
                  <th>Amount</th>
                  <th>Date </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1 + startIndex}</td>

                    <td>{item.transaction_id}</td>
                    <td>{ capitalizeWords( item.payment_mode ? item.payment_mode : 'N/A')}</td>
                    <td style={{ color: item.payment_type === 'credit' ? 'green' : item.payment_type === 'Debit' ? 'Debit' : 'red' }}>
                      {capitalizeWords (item.payment_type ?? 'N/A')}
                    </td>


                    <td>{item.transaction_amount}</td>

                    <td>{item.transaction_date}</td>
                    <td >
                      <span className={`badge bg-${item.transaction_status === "success"
                        ? "success"
                        : item.transaction_status === "pending"
                          ? "warning"
                          : "danger"
                        }`}>
                        {item.transaction_status}
                      </span>
                    </td>
                    <td >
                      <div className="d-flex gap-1 action_button">
                        <OverlayTrigger
                          key="View"
                          placement="top"
                          overlay={<Tooltip id={`View`}>View</Tooltip>}
                        >
                          <button
                            onClick={() => handleviveCustomerClick(item.id)}
                            className="bg-success"
                          >
                            <FaEye className="color_link" />
                          </button>
                        </OverlayTrigger>
                      </div></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <div className="d-flex gap-2">
              <div>
                <button
                  className="border-0 "
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <IoIosArrowBack />

                </button>
              </div>
              <div className="pagecurrent">
                {currentPage}
              </div>
              <div>
                <button
                  className="border-0 "
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >

                  <IoIosArrowForward />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
