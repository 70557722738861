import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeSlash, EyeFill } from "react-bootstrap-icons";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { FaFacebookF } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [browserId, setBrowserId] = useState('')
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [logoData, setLogoData] = useState(null);
  console.warn(logoData)


  const [error, setError] = useState(null);




  useEffect(() => {
    // Fetch data from API
    axios
      .post("https://broker.swaninvestment.in/API/public/api/employee-logo-withoutlogin")
      .then((response) => {
        if (response.data.status === 1) {
          setLogoData(response.data); // Set the API response data if status is 1 (Success)
        } else {
          setError("Failed to fetch data.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching data");
      });
  }, []);



  // useEffect(() => {
  //   const broker_id = localStorage.getItem("bear_token");



  //   if (broker_id ) {
  //     navigate('/home');
  //   }
  //   let browserId = Cookies.get('browserId');


  //   if (!browserId) {
  //     browserId = uuidv4();
  //     Cookies.set('browserId', browserId, { expires: 20 });
  //   }
  //   setBrowserId(browserId)
  // }, []);




        const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onInputChangeEmail = (e) => {
    setEmail(e.target.value);

    const emailWithoutSpaces = e.target.value.replace(/\s+/g, ''); // Remove all spaces
    console.warn("Processed Email: ", emailWithoutSpaces); // Debugging
    setEmail(emailWithoutSpaces);
    setEmail(e.target.value.trim());
  };

  const onInputChangePassword = (e) => {
    setPassword(e.target.value);
    const passwordWithoutSpaces = e.target.value.replace(/\s+/g, ''); // Remove all spaces
    setPassword(passwordWithoutSpaces);

  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };


  const Submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (!email || !password) {
        toast.error("Email or password is missing!");
        setLoading(false);
        return;
      }



      if (!rememberMe) {
        toast.error("Please check the Remember me checkbox!");
        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}login-employee`;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      formData.append('browser_id', browserId);

      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config)
        .then(function (response) {
          if (response.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: response.data.data.message,
              color: "white",
              background: 'transparent',
              timer: 2000,
            }).then((result) => {
              navigate('/home');
              localStorage.setItem("userid", response.data.data.id);
              localStorage.setItem("bear_token", response.data.bear_token);
              localStorage.setItem("smalladmins_id", response.data.data.smalladmins_id);
              localStorage.setItem("browserId", browserId);
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "Warning",
              text: response.data.message,
              color: "white",

              timer: 2000,
            }).then(() => {
              if (response.data.status === 0) {
                navigate('/');
              }
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setLoading(false);
    }
  };



  return (
    <main className="main" id="top">
      <div>
        <img
          className="bg-auth-circle-shape"
          src="assets/img/icons/spot-illustrations/bg-shape.png"
          alt=""
          width={250}
        />
        <img
          className="bg-auth-circle-shape-2"
          src="assets/img/icons/spot-illustrations/shape-1.png"
          alt=""
          width={150}
        />
        <div className="card overflow-hidden z-1">
          <div className="card-body p-0">
            <div className="row g-0 h-100">
              <div className="col-md-5 text-center bgcolornew d-flex align-items-center">
                <div className=''>
                  <div
                    className="position-relative p-4 pt-md-5 pb-md-7"
                    data-bs-theme="light"
                  >
                    <div
                      className="bg-holder bg-auth-card-shape"
                      style={{
                        backgroundImage:
                          "url(assets/img/icons/spot-illustrations/half-circle.png)"
                      }}
                    />
                    <div className="z-1 position-relative">
                      <a
                        className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder"
                        href="index.php"
                      >
                        <div>

                        </div>                        </a>

                      {logoData && logoData.logo_url && logoData.data ? (
                        <div>
                          <img
                            src={`${logoData.logo_url}/${logoData.data}`} // Full logo URL
                            alt="Employee Logo"
                            style={{ width: "200px", height: "auto" }}
                          />
                          <p className='mt-5  text-start'>{logoData.short_desc}</p> {/* Display the short description */}
                        </div>
                      ) : (
                        <p>No logo data available</p>
                      )}


                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5" data-bs-theme="light">
                    <p className="text-dark">
                      Don't have an account?
                      <br />
                      <a className="text-dark" href="#">
                        Get started!
                      </a>
                    </p>
                    <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-dark opacity-75">
                      Read our{" "}
                      <a
                        className="text-decoration-underline text-dark"
                        href="#!"
                      >
                        terms
                      </a>{" "}
                      and{" "}
                      <a
                        className="text-decoration-underline text-dark"
                        href="#!"
                      >
                        conditions
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7 d-flex flex-center">
                <div className="p-4 p-md-5 flex-grow-1">
                  <div className="row flex-between-center">
                    <div className="col-auto">
                      <h3 className='text-primary mb-3'> Employee Login</h3>
                    </div>
                  </div>
                  <form onSubmit={Submit} className='input-style-1'>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="card-email">
                        Email address
                      </label>
                      <input
                        value={email}
                        onChange={onInputChangeEmail}
                        className="form-control logindetails"
                        id="card-email"
                        type="email"
                      />
                    </div>
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="card-password">
                          Password
                        </label>
                      </div>
                      <div className="input-group">
                        <input
                          value={password}
                          onChange={onInputChangePassword}
                          className="form-control logindetails"
                          id="card-password"
                          type={showPassword ? "text" : "password"}
                        />
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <EyeSlash className="fs-3 eye" style={{ height: "21px" }} />
                          ) : (
                            <EyeFill className="fs-3 eye" style={{ height: "21px" }} />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="row flex-between-center">
                      <div className="col-auto">
                        <div className="form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="card-checkbox"
                            checked={rememberMe}
                            onChange={handleRememberMe}
                          />
                          <label className="form-check-label mb-0" htmlFor="card-checkbox">
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-auto">
                        <Link className="fs-10" to="/Forgetpassword">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        disabled={loading}
                        className="btn btn-primary d-block w-100 mt-3"
                        type="submit"
                        name="submit"
                      >
                        Log in
                        {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                      </button>
                    </div>
                  </form>
                  <div className="position-relative mt-4">
                    <hr />
                    <div className="divider-content-center">or log in with</div>
                  </div>
                  <div className="singin-option pt-40">
                    <p className="text-sm text-medium text-center text-gray">Easy Sign In With</p>
                    <div className="button-group pt-40 pb-40 d-flex justify-content-center flex-wrap">
                      {/* <button className="main-btn primary-btn-outline m-2">
      <FaFacebookF className="lni lni-facebook-fill mr-10" />
      Facebook
    </button>
    <button className="main-btn danger-btn-outline m-2">
      <FaGoogle className="lni lni-google mr-10" />
      Google
    </button> */}
                    </div>
                    {/* <p class="text-sm text-medium text-dark text-center">
                Don’t have any account yet?
                <a href="signup.html">Create an account</a>
              </p> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </main>
  )
}
