import React from 'react'

export default function Transactiondetails() {
  return (
    <div>
  <div className="card mb-3">
    <div
      className="bg-holder d-none d-lg-block bg-card"
      style={{
        backgroundImage:
          "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
      }}
    />
    {/*/.bg-holder*/}
    <div className="card-body position-relative">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="mb-0">Transaction Details</h3>
        </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div
      id="tableExample"
      data-list='{"valueNames":["TransactionID","EmployeeID","TransactionDate","TransactionAmount","TransactionType","BankDetails"],"page":5,"pagination":true}'
    >
      <div className="row justify-content-end g-0  p-3">
        <div className="col-auto col-sm-5 mb-3">
          <form>
            <div className="input-group">
              <input
                className="form-control form-control-sm shadow-none search"
                type="search"
                placeholder="Search..."
                aria-label="search"
              />
              <div className="input-group-text bg-transparent">
                <span className="fa fa-search fs-10 text-600" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="table-responsive scrollbar">
        <table className="table table-bordered table-striped fs-10 mb-0">
          <thead className="bg-200">
            <tr>
              <th className="text-900 sort" data-sort="TransactionID">
                Transaction ID
              </th>
              <th className="text-900 sort" data-sort="EmployeeID">
                {" "}
                Employee ID
              </th>
              <th className="text-900 sort" data-sort="TransactionDate">
                - Transaction Date
              </th>
              <th className="text-900 sort" data-sort="TransactionAmount">
                Transaction Amount
              </th>
              <th className="text-900 sort" data-sort="TransactionType">
                Transaction Type
              </th>
              <th className="text-900 sort" data-sort="BankDetails">
                Bank Details
              </th>
            </tr>
          </thead>
          <tbody className="list">
            <tr>
              <td className="TransactionID">Anna</td>
              <td className="EmployeeID">123456</td>
              <td className="TransactionDate">30-05-2023</td>
              <td className="TransactionAmount">60,000</td>
              <td className="TransactionType">
                <small className="badge rounded badge-subtle-secondary dark__bg-1000">
                  IMPS
                </small>
              </td>
              <td className="BankDetails">98657458745115</td>
            </tr>
            <tr>
              <td className="TransactionID">Jhon</td>
              <td className="EmployeeID">9854875</td>
              <td className="TransactionDate">25-05-2023</td>
              <td className="TransactionAmount">10,000</td>
              <td className="TransactionType">
                <small className="badge rounded badge-subtle-success false">
                  RTGS
                </small>
              </td>
              <td className="BankDetails">9658745212</td>
            </tr>
            <tr>
              <td className="TransactionID">Silver</td>
              <td className="EmployeeID">1542452</td>
              <td className="TransactionDate">09-05-2023</td>
              <td className="TransactionAmount">14,000</td>
              <td className="TransactionType">
                <small className="badge rounded badge-subtle-success false">
                  RTGS
                </small>
              </td>
              <td className="BankDetails">958742</td>
            </tr>
            <tr>
              <td className="TransactionID">Gold</td>
              <td className="EmployeeID">9854875</td>
              <td className="TransactionDate">25-05-2023</td>
              <td className="TransactionAmount">10,000</td>
              <td className="TransactionType">
                <small className="badge rounded badge-subtle-success false">
                  RTGS
                </small>
              </td>
              <td className="BankDetails">9658745212</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row align-items-center mt-3 p-2">
        <div className="pagination d-none" />
        <div className="d-flex justify-content-between">
          <div className="">
            <p className="mb-0 fs-10">
              <span
                className="d-none d-sm-inline-block"
                data-list-info="data-list-info"
              />
              <span className="d-none d-sm-inline-block"> —</span>
              <a className="fw-semi-bold" href="#!" data-list-view="*">
                View all
                <span
                  className="fas fa-angle-right ms-1"
                  data-fa-transform="down-1"
                />
              </a>
              <a
                className="fw-semi-bold d-none"
                href="#!"
                data-list-view="less"
              >
                View Less
                <span
                  className="fas fa-angle-right ms-1"
                  data-fa-transform="down-1"
                />
              </a>
            </p>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-sm btn-primary"
              type="button"
              data-list-pagination="prev"
            >
              <span>Previous</span>
            </button>
            <button
              className="btn btn-sm btn-primary px-4 ms-2"
              type="button"
              data-list-pagination="next"
            >
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


    </div>
  )
}
