import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Termsandcondition() {
  const broker_id = localStorage.getItem('smalladmins_id');
  const [sections, setSections] = useState([]);
  const [image, setImage] = useState('');
  const [baseurl, setBaseurl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);



  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append('smalladmins_id', broker_id);
      const token = localStorage.getItem('bear_token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}emp_cms-terms-conditions`, formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data',
          },
        }
      );

      const data = response.data.data;
      const data1 = response.data.image_url;

      setBaseurl(data1);
      setImage(data.image);
      const sectionsArray = data.descriptions.split(/\b\d+\.\s+/).filter(section => section.trim());
      setSections(sectionsArray);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  if (loading) {
    return <div>
       <div id="preloader">
  <div id="loading-center">
    <div className="loader">
      <div className="waviy">
        <img
          src="https://swaninvestment.in/frontant/assets/img/loader.png"
          alt="Loader image"
        />
      </div>
    </div>
  </div>
</div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              'url(../../assets/img/icons/spot-illustrations/corner-4.png)',
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <h2 className="card-title">Title Goes Here</h2>
            <div>
              <img
                className=""
                src={`${baseurl}/${image}`}
                width={100}
                height={100}
                alt=""
              />
            </div>
            <div className="card-body">
              {sections.map((section, index) => (
                <p key={index} className="card-text">
                  <span className="section-number">{index + 1}. </span>
                  {section}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
